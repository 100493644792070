
export default {
  htmlLogo: require('assets/images/logos/html.png'),
  cssLogo: require('assets/images/logos/css.png'),
  reactLogo: require('assets/images/logos/react.png'),
  typescriptLogo: require('assets/images/logos/typescript.png'),
  cppLogo: require('assets/images/logos/c++.png'),
  javaLogo: require('assets/images/logos/java.png'),
  pythonLogo: require('assets/images/logos/python.png'),
  swaggerLogo: require('assets/images/logos/swagger.png'),
  sortingProject: require('assets/images/projects/sorting-visualizer.png'),
  pathfindingProject: require('assets/images/projects/pathfinding-visualizer.png'),
  threeJSSortingVisualizer: require('assets/images/projects/3d-sorting-visualizer.png'),
  threeJSSortingVisualizerLive: require('assets/images/projects/3d-sorting-visualizer.gif'),
  solarSystem: require('assets/images/projects/solar-system.png'),
  solarSystemLive: require('assets/images/projects/solar-system.gif'),
  propsLogo: require('assets/images/projects/props/props.png'),
  propsAd: require('assets/images/projects/props/props-ad-parents.png'),
  propsWebsite: require('assets/images/projects/props/props-website.png'),
  propsSignUp: require('assets/images/projects/props/props-signup.png'),
  propsEditProfile: require('assets/images/projects/props/props-edit-profile.png'),
  propsIAP: require('assets/images/projects/props/props-iap.png'),
  benevLogo: require('assets/images/projects/benev/benev.png'),
  benevHome: require('assets/images/projects/benev/benev-home.png'),
  benevSignUpFlow: require('assets/images/projects/benev/benev-signup-flow.gif'),
  benevPayments: require('assets/images/projects/benev/benev-payments.png'),
  benevCheckout: require('assets/images/projects/benev/benev-checkout.png')
}
